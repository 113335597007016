





















































































































































































































































































































/* eslint-disable @typescript-eslint/no-use-before-define */
/* eslint-disable @typescript-eslint/naming-convention */
import {
  defineComponent,
  ref,
  PropType,
  computed,
  onMounted,
} from '@nuxtjs/composition-api';
import { SfSelect } from '@storefront-ui/vue';
import { useCountrySearch } from '~/composables';
import addressGetter from '~/modules/customer/getters/addressGetter';
import userShippingGetters from '~/modules/customer/getters/userShippingGetters';
import { mergeItem } from '~/helpers/asyncLocalStorage';
import useShipping from '~/modules/checkout/composables/useShipping';
import useBilling from '~/modules/checkout/composables/useBilling';
import useUserAddress from '~/modules/customer/composables/useUserAddress';

import { DeliveryAddress } from '~/composables/types';

import { debounce } from 'lodash-es';

import { parsePhoneNumberFromString } from 'libphonenumber-js';

interface ListCountry {
  id: string;
  label: string;
  englishLabel: string;
  abbreviation: string;
}

interface SmartySuggestion {
  street_line: string;
  secondary: string;
  city: string;
  state: string;
  zipcode: string;
}

export default defineComponent({
  components: {
    SfSelect,
    CheckoutStep: () => import('~/modules/checkout/components/CheckoutStep.vue'),
  },
  props: {
    title: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    countries: {
      type: Array as PropType<ListCountry[]>,
      default: () => [],
    },
    step: {
      type: String,
      default: 'shipping',
    },
    modelValue: {
      type: Object as PropType<DeliveryAddress>,
      default: () => ({
        firstname: '',
        lastname: '',
        street: '',
        apartment: '',
        city: '',
        region: '',
        country_code: 'US',
        postcode: '',
        telephone: '',
      }),
    },
    showStepButtons: {
      default: true,
      type: Boolean,
    },
    addAddressToCart: {
      default: false,
      type: Boolean,
    },
  },
  setup(props, { emit }) {
    const LASTNAME_PLACEHOLDER = '--';
    const DEFAULT_ADDRESS_DEBOUNCE_TIMER = 800;

    const { search: searchCountry } = useCountrySearch();
    const {
      save: saveShipping,
      searchAddresses,
      validateAddress,
      invalidAddressFormat,
    } = useShipping();
    const { save: saveBillingAddress } = useBilling();
    const { load: loadUserShipping, setDefaultAddress } = useUserAddress();

    const fullname = ref<any>('');

    const country = ref<any>(null);

    const addressSuggestions = ref<SmartySuggestion[]>([]);
    const userShipping = ref<any>(null);

    const autocompletableAddress = ref<string>('');

    const isSetAsDefaultRequested = ref(false);

    const invalidSmartyAddress = ref(false);

    const ref_fullname = ref(null);
    const ref_autocompletable_address = ref(null);
    const ref_telephone = ref(null);

    const showErrorFullname = ref(false);
    const showErrorCountry = ref(false);
    const showErrorAddress = ref(false);
    const showErrorTelephone = ref(false);

    const showValidationsErrors = computed(() => {
      const validations = [
        showErrorFullname.value,
        showErrorCountry.value,
        showErrorAddress.value,
        showErrorTelephone.value,
      ];

      return validations.some((validation) => !!validation);
    });

    const shippingDetails : any = computed({
      get(): DeliveryAddress {
        return props.modelValue;
      },
      set() {},
    });

    const regionInformation = computed(() => addressGetter.regionList(country.value));

    const selectingSuggestion = ref(false);

    const showSuggestions = computed(() => {
      const address = shippingDetails.value as DeliveryAddress;

      return (
        selectingSuggestion.value
        && (address.country_code === 'US' || addressSuggestions.value.length > 0)
      );
    });

    const changeCountry = async (id: string) => {
      const address = shippingDetails.value as DeliveryAddress;

      changeShippingDetails('country_code', id);
      const newCountry = await searchCountry({ id });
      address.region = '';
      country.value = newCountry;
    };

    const addressFormat = (address: {
      street_line: string;
      secondary?: string;
      city: string;
      state: string;
      zipcode: string;
    }) => `${address?.street_line} ${address?.secondary}, ${address?.city}, ${address?.state} ${address?.zipcode}`.trim();

    const selectSuggestion = async (suggestion: SmartySuggestion) => {
      selectingSuggestion.value = true;
      changeShippingDetails('street', suggestion.street_line);
      changeShippingDetails('apartment', suggestion.secondary);
      changeShippingDetails('city', suggestion.city);
      changeShippingDetails('region', suggestion.state);
      changeShippingDetails('postcode', suggestion.zipcode);

      selectingSuggestion.value = false;
      autocompletableAddress.value = addressFormat(suggestion);
      await validateDeliveryAddress();
    };

    const fillDeliveryAddress = (fillInputs = false) => {
      const address = shippingDetails.value as DeliveryAddress;

      if (addressIsValid()) {
        autocompletableAddress.value = `${address?.street} ${
          address.apartment ? address.apartment : ''
        }, ${address?.city}, ${address?.region} ${address?.postcode}`.trim();
      }

      if (address.firstname.length > 0 && address.lastname.length > 0) {
        fullname.value = `${address.firstname} ${
          address.lastname === LASTNAME_PLACEHOLDER ? '' : address.lastname
        }`.trim();
      }
    };

    const searchAutocompletableAddress = async (search: string) => {
      changeShippingDetails('street', '');
      changeShippingDetails('apartment', '');
      changeShippingDetails('city', '');
      changeShippingDetails('region', '');
      changeShippingDetails('postcode', '');

      if (search.length < 22) {
        addressSuggestions.value = await searchAddresses(search);
      } else {
        validateInvalidSmartyAddress(search);
      }
    };

    const debounceSearchAutocompletableAddress = debounce(searchAutocompletableAddress, DEFAULT_ADDRESS_DEBOUNCE_TIMER);

    const validateInvalidSmartyAddress = async (search: string) => {
      const [_addressComponents, verdict] = await validateAddress(search);
      const formattedAddress = invalidAddressFormat(_addressComponents, verdict);

      Object.entries(formattedAddress).forEach(([key, value]) => {
        if (Object.keys(shippingDetails.value).includes(key)) {
          shippingDetails.value[key] = value;
        }
      });
      if (formattedAddress.extra) {
        shippingDetails.value.extra = formattedAddress.extra;
      }

      await validateDeliveryAddress();
    };

    const telephonePlaceholder = computed(() => {
      const address = shippingDetails.value as DeliveryAddress;

      const defaultPlaceholder = 'US';
      const placeholders = {
        CA: '(   )   -    ',
        US: '(   )   -    ',
      };

      return !!address?.country_code && !!placeholders[address?.country_code]
        ? placeholders[address?.country_code]
        : placeholders[defaultPlaceholder];
    });

    const addressIsValid = () => {
      const address = shippingDetails.value as DeliveryAddress;

      const validFullname = !!address.firstname
        && address.firstname.length > 1
        && !!address.lastname
        && address.lastname.length > 1;

      const validLines = !!address.street
        && !!address.city
        && !!address.postcode
        && !!address.region;

      const validCountry = !!address.country_code;

      const formattedTelephone = address.telephone ? parsePhoneNumberFromString(
        address.telephone,
        address.country_code as any,
      ) : address.telephone;

      // TO-DO: Telephone is optional now

      return [validFullname, validLines, validCountry].every((item) => !!item);
    };

    const handleAddressSubmit = async () => {
      debounceHandleAddressSubmit.cancel();
      const address = shippingDetails.value as DeliveryAddress;

      if (!addressIsValid()) {
        emit('validate-address', false);
        return;
      }

      const addressId = null;

      if (props.step === 'shipping') {
        if (!address.lastname) {
          address.lastname = LASTNAME_PLACEHOLDER;
        }

        const shippingDetailsData = {
          ...(shippingDetails.value as DeliveryAddress & { fullname?: string }),
          customerAddressId: addressId,
          save_in_address_book: false,
        };

        if (Object.keys(shippingDetailsData).includes('fullname')) {
          delete shippingDetailsData.fullname;
        }

        const detailsRegion = shippingDetailsData.region as string | { region_code: string };
        if (typeof detailsRegion !== 'string' && !!detailsRegion.region_code) {
          shippingDetailsData.region = detailsRegion.region_code;
        }

        const detailsStreet = shippingDetailsData.street as string | string[];
        if (typeof detailsStreet !== 'string' && Array.isArray(detailsStreet)) {
          shippingDetailsData.street = detailsStreet.join(' ');
        }

        await mergeItem('checkout', { shipping: shippingDetailsData });

        const shippingInfo = await saveShipping({
          shippingDetails: shippingDetailsData,
        });

        const shippingMethods = shippingInfo?.available_shipping_methods ?? [];

        if (addressId !== null && isSetAsDefaultRequested.value) {
          const [chosenAddress] = userShippingGetters.getAddresses(
            userShipping.value,
            { id: addressId },
          );
          chosenAddress.default_shipping = isSetAsDefaultRequested.value;
          if (chosenAddress) {
            await setDefaultAddress({ address: chosenAddress });
            userShipping.value = await loadUserShipping(true);
          }
        }
        if (shippingMethods.length > 0) {
          emit('shipping-methods-loaded');
        }
      }

      emit('validate-address', true);
    };

    const debounceHandleAddressSubmit = debounce(handleAddressSubmit, DEFAULT_ADDRESS_DEBOUNCE_TIMER);

    const handleBillingAddressSubmit = async () => {
      const addressId = null;
      const billingDetailsData = {
        billingDetails: {
          ...(shippingDetails.value as DeliveryAddress),
          customerAddressId: addressId === null ? null : String(addressId),
          sameAsShipping: props.step === 'shipping',
          save_in_address_book: false,
        },
      };

      const detailsRegion = billingDetailsData.billingDetails.region as string | { region_code: string };
      if (typeof detailsRegion !== 'string' && !!detailsRegion.region_code) {
        billingDetailsData.billingDetails.region = detailsRegion.region_code;
      }

      const detailsStreet = billingDetailsData.billingDetails.street as string | string[];
      if (typeof detailsStreet !== 'string' && Array.isArray(detailsStreet)) {
        billingDetailsData.billingDetails.street = detailsStreet.join(' ');
      }

      await saveBillingAddress(billingDetailsData);

      await mergeItem('checkout', { billing: billingDetailsData });

      if (props.step === 'billing') {
        emit('validate-address', true);
      }
    };

    const validateDeliveryAddress = async () => {
      if (props.step === 'shipping') {
        await debounceHandleAddressSubmit();
      }
      if (props.step === 'billing') {
        //
      }
    };

    const resetValidationErrors = () => {
      showErrorFullname.value = false;
      showErrorCountry.value = false;
      showErrorAddress.value = false;
      showErrorTelephone.value = false;
    };

    const validateInputs = () => {
      const address = shippingDetails.value as DeliveryAddress;

      const validFullname = !!address.firstname
        && address.firstname.length > 1
        && !!address.lastname
        && address.lastname.length > 1
        && fullname.value.length > 1;

      const validLines = !!address.street
        && !!address.city
        && !!address.postcode
        && !!address.region;

      const validCountry = !!address.country_code;

      const formattedTelephone = typeof address.telephone === 'string' ? parsePhoneNumberFromString(
        address.telephone,
        address.country_code as any,
      ) : null;

      const validTelephone = (!!address.telephone
          && address.telephone.length > 1
          && formattedTelephone?.isValid())
        || (props.addAddressToCart && address.telephone.length > 0);

      if (!validFullname) {
        showErrorFullname.value = true;
      }

      if (!validLines) {
        showErrorAddress.value = true;
      }

      if (!validCountry) {
        showErrorCountry.value = true;
      }

      if (!validTelephone) {
        showErrorTelephone.value = true;
      }

      const hasErrors = [showErrorFullname.value, showErrorAddress.value, showErrorCountry.value, showErrorTelephone.value].some((err) => !!err);
      return hasErrors;
    };

    const buildFullname = (value: string) => {
      const address = shippingDetails.value as DeliveryAddress;

      if (value.trimEnd().includes(' ')) {
        const indexOfSpace = value.indexOf(' ');
        const firstname = value.slice(0, indexOfSpace);
        const lastname = value.slice(indexOfSpace + 1);
        address.firstname = firstname;
        address.lastname = lastname;
      } else {
        address.firstname = value;
        address.lastname = LASTNAME_PLACEHOLDER;
      }
    };

    const changeShippingDetails = async (field: string, value: string) => {
      const address = shippingDetails.value as DeliveryAddress;

      if (field === 'fullname' && value.length > 0) {
        buildFullname(value);
      } else {
        address[field] = value;
      }

      emit('update-model-value', shippingDetails.value);
      const fieldsToValidate = ['fullname', 'telephone', 'country_code'];
      if (fieldsToValidate.includes(field)) {
        await localValidations(field);
      }
    };

    const localValidations = async (field: string) => {
      const address = shippingDetails.value as DeliveryAddress;

      if (field === 'fullname' && !(address.firstname.length > 1)) {
        emit('validate-address', false);
        return;
      }
      await validateDeliveryAddress();
    };
    const changeTelephoneNumber = async (event : { target: { value : string } }) => {
      const address = shippingDetails.value as DeliveryAddress;

      const formattedNumber = parsePhoneNumberFromString(
        event.target.value,
        'US',
      );

      address.telephone = formattedNumber && formattedNumber.isValid() ? formattedNumber.formatNational() : event.target.value;

      const realDetails = { ...address } as DeliveryAddress;
      realDetails.telephone = event.target.value;

      emit('update-model-value', realDetails);
      await validateDeliveryAddress();
    };

    const hideSuggestions = () => {
      setTimeout(() => {
        selectingSuggestion.value = false;
      }, 250);
    };

    const displaySaveAddresses = () => {
      emit('display-save-addresses');
    };

    const displayBookAddresses = () => {
      emit('display-book-addresses');
    };

    onMounted(async () => {
      const countryID = props.modelValue.country_code as string;
      if (countryID) {
        const newCountry = await searchCountry({ id: countryID });
        country.value = newCountry;
      } else {
        const newCountry = await searchCountry({ id: 'US' });
        country.value = newCountry;
        changeCountry('US');
      }
      fillDeliveryAddress();
      if (props.addAddressToCart && props.step === 'shipping') {
        await debounceHandleAddressSubmit();
      }
    });

    return {
      showValidationsErrors,
      showErrorFullname,
      showErrorAddress,
      showErrorTelephone,
      showErrorCountry,
      ref_fullname,
      ref_autocompletable_address,
      ref_telephone,
      fillDeliveryAddress,
      displaySaveAddresses,
      displayBookAddresses,
      invalidSmartyAddress,
      fullname,
      localValidations,
      hideSuggestions,
      changeTelephoneNumber,
      debounceHandleAddressSubmit,
      debounceSearchAutocompletableAddress,
      autocompletableAddress,
      selectingSuggestion,
      showSuggestions,
      addressSuggestions,
      addressFormat,
      selectSuggestion,
      changeShippingDetails,
      changeCountry,
      shippingDetails,
      regionInformation,
      handleAddressSubmit,
      handleBillingAddressSubmit,
      telephonePlaceholder,
      searchAutocompletableAddress,
      validateInputs,
      resetValidationErrors,
    };
  },
});
